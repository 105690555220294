
export default {
  name: 'LineThroughComponent',
  props: {
    size: {
      type: String,
      default: '24px',
    },
    color: {
      type: String,
      default: 'green-text',
    },
    fontStyle: {
      type: String,
      default: 'normal-style',
    },
    lineBg: {
      type: String,
      default: 'line-green',
    },
    pb: {
      type: String,
      default: '0',
    },
    width: { type: String, default: 'w-85' },
    textBg: { type: String, default: 'grey-bg' },
    textShadow: { type: Boolean, default: true },
  },
}
