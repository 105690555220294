
import {mapGetters} from 'vuex'
import moment from 'moment'

import Button from '~/components/ui/Button.vue'

export default {
  components: {Button},
  data() {
    return {
      options: {
        autoplay: {duration: 3500},
        navigation: {
          nextEl: '.swiper-button-next-third',
          prevEl: '.swiper-button-prev-third',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return '<div class="' + className + '"></div>'
          },
        },
        slidesPerView: 1,
        grabCursor: true,
      },
    }
  },
  // async fetch() {
  //   await this.$store.dispatch('review/fetchRandomReview')
  // },
  computed: {
    ...mapGetters({
      review: 'review/getRandomReviews',
    }),
    // list() {
    //   if (this.review.length) return this.review
    //
    //   return []
    // }
  },
  methods: {
    moment,
  },
}
