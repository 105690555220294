import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7151dd22&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7151dd22&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7151dd22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/var/www/vape_shop_nuxt_2/components/Banner.vue').default,Loading: require('/var/www/vape_shop_nuxt_2/components/Loading.vue').default,ProductsItem: require('/var/www/vape_shop_nuxt_2/components/Products/Item.vue').default,ButtonPagination: require('/var/www/vape_shop_nuxt_2/components/ButtonPagination.vue').default,Manufacture: require('/var/www/vape_shop_nuxt_2/components/Manufacture.vue').default,Comments: require('/var/www/vape_shop_nuxt_2/components/Comments.vue').default,Guides: require('/var/www/vape_shop_nuxt_2/components/Guides.vue').default})
