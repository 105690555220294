
export default {
  name: 'FilterComponent',
  props: {
    active: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabActive: this.active,
    }
  },
  methods: {
    switchFilter(filterItem) {
      this.tabActive = filterItem

      this.$emit('update:active', this.tabActive)
    },
  },
}
