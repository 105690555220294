
import { mapGetters } from 'vuex'

import LineThrough from '~/components/ui/LineThrough.vue'

export default {
	components: { LineThrough },
	computed: {
		...mapGetters({ selectedList: 'product/getSelectedList' })
	}
}
