
import { mapActions, mapGetters } from 'vuex'

import LastSeen from '~/components/Products/LastSeen.vue'
import Manufacture from '~/components/Manufacture.vue'
import Comments from '~/components/Comments.vue'
import Guides from '~/components/Guides.vue'
import FilterItem from '~/components/ui/Filter.vue'
import ButtonPagination from '~/components/ButtonPagination.vue'

export default {
	name: 'IndexPage',
	components: {
		LastSeen,
		Manufacture,
		Comments,
		Guides,
		FilterItem,
		ButtonPagination
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('product/RESET_PRODUCT_LIST')

		next()
	},
	async asyncData({ store }) {
		let totalRecords = 0

		await store.dispatch('section/getSections')
		await store.dispatch('banners/getBannerList')
		await store.dispatch('brands/fetchBrands', {})
		await store.dispatch('product/getSelectedProduct')
		await store.dispatch('review/fetchRandomReview')

		const sections = Object.values(store.getters['section/getSectionIds'])
		const response = await store.dispatch('product/fetchNewBySection', {
			pagination: { page: 1, size: 8 },
			sections
		})

		if (response.status === 200) totalRecords = response.data.total_count

		return { totalRecords }
	},
	data() {
		return {
			tabActive: 'isNew',
			tabs: [
				{ title: 'Новинки', value: 'isNew' },
				{ title: 'Бестселлери', value: 'bestseller' },
				{ title: 'Вибір команди', value: 'team_choice' }
				// {title: 'Акції', value: 'promo'},
			],
			// totalRecords: 0,
			serverParams: {
				page: 1,
				size: 8
			},
			section: '',
			firstLoading: false,
			loading: false,
			isChangeTab: false
		}
	},
	head({ $seo, $config, $route }) {
		return $seo({
			title: `Вейп-Шоп ${$config.projectName}  одноразки, рідини, POD купити в Україні, Вінниця`,
			openGraph: {
				title: `Вейп-Шоп ${$config.projectName}  одноразки, рідини, POD купити в Україні, Вінниця`,
				url: `${$config.projectURL}${$route.fullPath}`
			},
			twitter: {
				title: `Вейп-Шоп ${$config.projectName}  одноразки, рідини, POD купити в Україні, Вінниця`
			}
		})
	},
	computed: {
		...mapGetters({
			productList: 'product/getProductList',
			sectionIds: 'section/getSectionIds'
		}),
		totalPages() {
			return Math.ceil(this.totalRecords / this.serverParams.size)
		},
		sections() {
			return Object.values(this.sectionIds)
		}
	},
	methods: {
		...mapActions({
			fetchProductList: 'product/fetchList',
			fetchNewProducts: 'product/fetchNewBySection',
			fetchTeamChoiceProducts: 'product/fetchTeamChoiceProducts'
		}),
		async updatePaginationParams(param) {
			let dataParams = {}

			this.serverParams = param.pagination

			if (this.tabActive === 'bestseller') {
				dataParams = {
					pagination: this.serverParams,
					bestseller: true
				}
			} else if (this.tabActive === 'team_choice') {
				dataParams = {
					pagination: this.serverParams
				}
			} else if (this.tabActive === 'isNew') {
				dataParams = {
					pagination: this.serverParams,
					sections: this.sections
				}
			}

			await this.loadItems(dataParams, this.tabActive === 'isNew')
		},
		async loadItems(data, isNew) {
			try {
				this.loading = true

				let response = null
				if (isNew) {
					response = await this.fetchNewProducts({
						sections: Object.values(this.sectionIds),
						...data
					})
				} else if (this.tabActive === 'team_choice') {
					response = await this.fetchTeamChoiceProducts(data)
				} else response = await this.fetchProductList(data)

				if (response.status === 200) {
					this.totalRecords = response.data.total_count
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async changeTab(tab) {
			this.isChangeTab = true

			this.tabActive = tab
			this.serverParams = { page: 1, size: 8 }

			const params = {
				reset: true,
				pagination: this.serverParams
			}

			switch (tab) {
				case 'bestseller':
					await this.loadItems({ bestseller: true, ...params })
					break
				case 'team_choice':
					await this.loadItems({
						...params
					})
					break
				case 'isNew':
					await this.loadItems({ ...params, sections: this.sections }, true)
					break
				default:
					break
			}

			this.isChangeTab = false
		}
	}
}
