
import Button from '~/components/ui/Button.vue'

export default {
	components: { Button },

	data() {
		return {
			activeTabs: []
		}
	},
	methods: {
		setActiveTabs(tab) {
			if (!this.activeTabs.includes(tab)) {
				this.activeTabs.push(tab)
			} else {
				const foundIndex = this.activeTabs.findIndex(el => el === tab)
				this.activeTabs.splice(foundIndex, 1)
			}
		}
	}
}
