
import { mapGetters } from 'vuex'

import Button from '~/components/ui/Button.vue'
import LineThrough from '~/components/ui/LineThrough.vue'

export default {
	components: { LineThrough, Button },
	data() {
		return {
			// brands: [],
			swiperOptions: {
				autoplay: { duration: 3000 },
				effect: 'coverflow',
				coverflowEffect: {
					rotate: 0,
					stretch: 0,
					depth: 0,
					modifier: 0
				},
				centeredSlides: true,
				navigation: {
					nextEl: '.slider_button-next',
					prevEl: '.slider_button-prev'
				},
				spaceBetween: 10,
				breakpoints: {
					900: { slidesPerView: 6 },
					700: { slidesPerView: 4 },
					500: { slidesPerView: 3 },
					320: { slidesPerView: 2 }
				}
			}
		}
	},
	computed: {
		...mapGetters({
			brands: 'brands/getBrands'
		})
	},
	methods: {
		setAltImg(event) {
			event.target.src = '/images/img_not_found.png'
		},
		convertUrl(url) {
			if (url.includes(' ')) {
				return url.split(' ').join('%20')
			}

			return url
		}
	}
}
